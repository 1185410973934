 export const wellnessProductData = [
{
  id: 1,
  name: "Mangosteen Coffee",
  url: `${process.env.PUBLIC_URL}/images/mangosteen.png`,
  price: 230,
   weight:120,
      stock: 10,
  description: "Mangosteen Coffee with moringa is perfect to start your day for its true coffee taste sweetened by stevia.Mangosteen is a potent source of anti-oxidant blended with Moringa leaf powder that is a remarkable source of nutrition. Mangosteen boosts immune system, Moringa controls blood pressure and Stevia protects the bone and reduces heart burn",               
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/mangosteen.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/mangoesteen2.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/mangoesteen3.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/mangoesteen4.png`,
      ],
  productdetails:"10 Sachets of 12g sweetened with stevia,  BFAD#1009889000",
     sizecolor:false,
     shipping:'1 cartoon box, size: L-10cm x W-50cm x H-60cm',
},
{
  id: 2,
  name: "Acai Berry 1000ml",
  url:  `${process.env.PUBLIC_URL}/images/acaiberry.jpg`,
  price: 2300,
   weight:1000,
      stock: 10,
  description:"What are some of ACAI BERRY’s health benefits? The acai berry has numerous health benefits, and has been proven to protect virtually every system in the body. A few of these benefits include: Weight loss: Acai is composed of several vitamins and minerals, and works to suppress hunger, increase energy, and stimulate metabolism. Digestive System Aid: A high level of fiber makes acai berry a natural colon cleanser. Boosts the Immune System: The high concentration of antioxidants in acai berry helps to support the immune system by fortifying cells and fighting off intruders. Acai berry has also been proven to: Regulate sugar level, Prevents Cardio vascular disease, Improves heart function , Maintain Healthy Teeth and Gums, Prevent Cancer. ",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/acaiberry.jpg`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/acai2.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/acai3.png`,
      ],
   productdetails:"1 plastic Bottle of 1000ml acaiberry juice, ingredients: Acai Berry, Craneberries, Mangosteen, Green Tea, Roselle, BFAD#1009889000", 
   sizecolor:false,
    shipping:'1bottle, size: 1L',
  },
{
  id: 3,
  name: "Barley Grass Juice",
  url:  `${process.env.PUBLIC_URL}/images/barleyjuice.jpg`,
  price: 960,
   weight:120,
      stock: 10,
  description:"Provides a multiwide of essential functions of our body system such as strong immunity absorption of iron growth and repair of all body tissues, prevent cardio vascular problem and maintenance of healthy joints and blood vessels.",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/barleyjuice.jpg`,
       `${process.env.PUBLIC_URL}/images/barley.jpg`,
       
      ],
  productdetails:"10 Sachets of 3g sweetened with stevia, BFAD#1009889000",
     sizecolor:false,
     shipping:'1 cartoon box, size: L-10cm x W-50cm x H-60cm',
 },
{
  id: 4,
  name: "Acai Berry 500ml",
  url:  `${process.env.PUBLIC_URL}/images/acai.jpg`,
  price: 1300,
   weight:500,
      stock: 10,
  description: "What are some of ACAI BERRY’s health benefits? The acai berry has numerous health benefits, and has been proven to protect virtually every system in the body. A few of these benefits include: Weight loss: Acai is composed of several vitamins and minerals, and works to suppress hunger, increase energy, and stimulate metabolism. Digestive System Aid: A high level of fiber makes acai berry a natural colon cleanser. Boosts the Immune System: The high concentration of antioxidants in acai berry helps to support the immune system by fortifying cells and fighting off intruders. Acai berry has also been proven to: Regulate sugar level, Prevents Cardio vascular disease, Improves heart function , Maintain Healthy Teeth and Gums, Prevent Cancer. ",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/acai.jpg`,
      `${process.env.PUBLIC_URL}/images/dealsthumb/acai2.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/acai3.png`,
      ],
  productdetails:"1 plastic Bottle of 500ml acaiberry juice, BFAD#1009889000",
     sizecolor:false,
     shipping:'1bottle, size: 500ml',
  },
{
  id: 5,
  name: "Absorbent Cee",
  url:  `${process.env.PUBLIC_URL}/images/absorbent c.png`,
  price: 750,
   weight:120,
      stock: 10,
  description:"Vitamin Cee for strong immune system and prevent cardio vascular disease",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/absorbent c.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/absorbentcee1.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/absorbentcee2.png`,
       
      ],
  productdetails:"100 capsules/ 568.18mg per capsule, Sodium Ascorbate,  BFAD#1009889000",
     sizecolor:false,
     shipping:'1 cartoon box, size: L-10cm x W-50cm x H-60cm',
 },
{
  id: 6,
  name: "Primeshape",
  url:  `${process.env.PUBLIC_URL}/images/primeshape.jpg`,
  price: 143,
   weight:120,
      stock: 10,
  description:"Can help improve our brain functions, lowers the risk of diabetes and it is a good appetite suppressant that helps elemenate free radicals.It can increase blood circulation and increase your stamina. It prevents high blood pressure and it is loaded with antioxidants that helps boosts your immune system",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/primeshape.jpg`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/primeshape1.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/primeshape2.png`,
      
      ],
  productdetails:"15g of 5 sachets sweetened with stevia,  BFAD#1009889000",
  sizecolor:false,
  shipping:'1 cartoon box, size: L-10cm x W-50cm x H-60cm',

 },
{
  id: 7,
  name: "Arctic C",
  url: `${process.env.PUBLIC_URL}/images/artic c.png`,
  price: 750,
   weight:35,
      stock: 10,
  description:"blend of omega 3,6 and 9 which contains essential fatty acids EPA and DHA derived from the tissues of oily fish that can contributes to the healthy function of the heart and normal the maintenance of the brain. Skin, Lungs, Eyes, and Blood Vessels: Replenishing Omega-3 is essential for the optimal function of these organs1. Scientifically Supported: Numerous studies highlight the benefits of high Omega-3 consumption for cardiovascular health1. Cellular Health: Omega-3 fatty acids are incorporated into cellular membranes throughout the body, supporting various organs, Promotes Cardiovascular Well-Being: EPA and DHA play vital roles in organ function, making Omega-3 fats crucial for heart health",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/artic c.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/arcticc1.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/arcticc2.png`,
       
      ],
  productdetails:"30 softgels , Fish Oil with Omega 3,6 and 9, BFAD# 1009889000",
  sizecolor:false,
  shipping:'1 plastic bottle, size: 150ml',

  },
{
  id: 8,
  name: "Garcinia Cambogia",
  url:  `${process.env.PUBLIC_URL}/images/garcinia.jpg`,
  price: 450,
   weight:120,
      stock: 10,
  description: "reduces blood pressure and the inflammatory process associated with heart disease, prevents heart attack and stroke and neutralizes the damaging effect fo free radicals",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/garcinia.jpg`,
      `${process.env.PUBLIC_URL}/images/Garcinia01.jpg`,
      
      ],
  productdetails:"15g of 10 sachets sweetened with stevia,  BFAD#1009889000",
  sizecolor:false,
  shipping:'1 cartoon box, size: L-10cm x W-50cm x H-60cm',

 },
{
  id: 9,
  name: "Gleam",
  url:  `${process.env.PUBLIC_URL}/images/gleam.jpg`,
  price: 2300,
   weight:150,
      stock: 10,
  description:"mixed collagen powdered milk with tremella, rich and powerful antioxidant, reduces signs of aging, boost immune system, supports healthy brain functions, improves blood circulation, promotes heart lungs and digestive health",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/gleam.jpg`,
      `${process.env.PUBLIC_URL}/images/gleam.jpg`,
      ],
  productdetails:"15g of 5 sachets sweetened with stevia,  BFAD#1009889000",
  sizecolor:false,
  shipping:'1 cartoon box, size: L-10cm x W-50cm x H-60cm',

 },
{
  id: 10,
  name: "Turmeric",
  url:  `${process.env.PUBLIC_URL}/images/turmeric.png`,
  price: 253,
   weight:150,
      stock: 10,
  description:"Natural tea and powerful immune booster. it contains high level of antioxidants and inflammatory properties. It is good in our body because it gives 11 natural ingredients to strenghten the body's immune system and keeping the body healthy.",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/turmeric.png`,
      `${process.env.PUBLIC_URL}/images/turmeric.png`,
      
      ],
  productdetails:"15g of 5 sachets sweetened with stevia,  BFAD#1009889000",
     sizecolor:false,
       shipping:'1plastic bottle, size:250ml',

  },
{
  id: 11,
  name: "Energy Bracelet",
  url:  `${process.env.PUBLIC_URL}/images/bracelet.png`,
  price: 3800,
   weight:130,
      stock: 10,
  description:"100% authentic quantum bracelet with scalar energy and is available for both men and woman to improves stamina, endurance and strength. it also calms the mind and restores balance and harmony to the body.",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/bracelet.png`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/bracele649.jpg`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/bracele640.jpg`,
       `${process.env.PUBLIC_URL}/images/dealsthumb/bracele641.jpg`,
      ],
  productdetails:"15g of 5 sachets sweetened with stevia,  BFAD#1009889000",
  sizecolor:true,
  shipping:'1 cardboard box, size: L-10cm x W-50cm x H-60cm',

  },
{
  id: 12,
  name: "Buah Merah",
  url:  `${process.env.PUBLIC_URL}/images/Buah merah.png`,
  price: 350,
   weight:120,
      stock: 10,
  description:"Helps in boosting body energy and immunity, detoxifying liver, preventing signs of aging, prevents hypertension and diabetes, regulates blood pressure, improves good cholesterol levels and sharpens memory",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/Buah merah.png`,
       `${process.env.PUBLIC_URL}/images/Buah merah.png`,
    
      ],
  productdetails:"Net wt 30g,  BFAD#1009889000",
     sizecolor:false,
    shipping:'1plastic bottle, size: 150ml',

 },
{
  id: 13,
  name: "Mangosteen choco",
  url:  `${process.env.PUBLIC_URL}/images/choco.jpg`,
  price: 230,
   weight:120,
      stock: 10,
  description: "Mangosteen Coffee with moringa is perfect to start your day for its true coffee taste sweetened by stevia.Mangosteen is a potent source of anti-oxidant blended with Moringa leaf powder that is a remarkable source of nutrition. Mangosteen boosts immune system, Moringa controls blood pressure and Stevia protects the bone and reduces heart burn",               
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/mangosteen.png`,
      `${process.env.PUBLIC_URL}/images/mangosteen.png`,
     
      ],
  productdetails:"10 Sachets of 12g sweetened with stevia,  BFAD#1009889000",
  sizecolor:false,
  shipping:'1 cartoon box, size: L-10cm x W-50cm x H-60cm',

  },
{
  id: 14,
  name: "Flushout",
  url:  `${process.env.PUBLIC_URL}/images/flushout.jpg`,
  price: 960,
   weight:120,
      stock: 10,
  description: "It detoxifies the body, helps fight obesity promotes weight loss, increases the metabolism, supports the immmune system, lowers blood sugar and helps to prevent and fight cancer.",
    thumbnails:
       [`${process.env.PUBLIC_URL}/images/flushout.jpg`,
          `${process.env.PUBLIC_URL}/images/detox.png`,
         
         ],
  productdetails:"15g of 5 sachets sweetened with stevia,  BFAD#1009889000",
  sizecolor:false,
  shipping:'1 cartoon box, size: L-10cm x W-50cm x H-60cm',

  },
{
  id: 15,
  name: "L-Glutathione",
  url:  `${process.env.PUBLIC_URL}/images/lglutathione.jpg`,
  price: 2895,
   weight:130,
      stock: 10,
  description: "Gluta dietary Supplement capsule that consist of one glutathione with an added Sulfhydryl group. It improves the immune system and protects the body from free radical damage. it can also flush out liver toxins that can achieve whiter skin.",
    thumbnails:
      [`${process.env.PUBLIC_URL}/images/lglutathione.jpg`,
        `${process.env.PUBLIC_URL}/images/glutathione.png`,
      ],
  productdetails:"800mg of 60 capsules, Gluta Diatary Supplement, BFAD#1009889000",
  sizecolor:false,
  shipping:'1plastic bottle, size: 150ml',

  },
];