// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Roboto', sans-serif;
}

/* You can specify different fonts for different elements (e.g., headings, paragraphs, buttons) as needed. */
h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA,4GAA4G;AAC5G;EACE,qCAAqC;AACvC","sourcesContent":["body {\n  font-family: 'Roboto', sans-serif;\n}\n\n/* You can specify different fonts for different elements (e.g., headings, paragraphs, buttons) as needed. */\nh1, h2, h3 {\n  font-family: 'Montserrat', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
