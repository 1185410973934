export const mensshoes = [
    { id: 420, name: 'nike01', price: 1500, weight:800, url:`${process.env.PUBLIC_URL}/fashion/mensshoes/nike01.jpg`, category: 'Mens Shoes', stock:0, page:1, description:'good Quality', 
      thumbnails:
      [`${process.env.PUBLIC_URL}/fashion/mensshoes/nike01.jpg`,
       `${process.env.PUBLIC_URL}/fashion/mensshoes/nike01.jpg`,
      ],}, 
    { id: 421, name: 'nike02', price: 1500, weight:800, url:`${process.env.PUBLIC_URL}/fashion/mensshoes/nike03.png`, category: 'Mens Shoes', stock:0, page:1, description:'good Quality', 
      thumbnails:
      [`${process.env.PUBLIC_URL}/fashion/mensshoes/nike03.png`,
       `${process.env.PUBLIC_URL}/fashion/mensshoes/nike03.png`,
      ],}, 
    { id: 422, name: 'nike01', price: 1500, weight:800, url:`${process.env.PUBLIC_URL}/fashion/mensshoes/nike01.jpg`, category: 'Mens Shoes', stock:0, page:1, description:'good Quality', 
     thumbnails:
      [`${process.env.PUBLIC_URL}/fashion/mensshoes/nike01.jpg`,
       `${process.env.PUBLIC_URL}/fashion/mensshoes/nike01.jpg`,
      ],}, 
    { id: 423, name: 'nike02', price: 1500, weight:800, url:`${process.env.PUBLIC_URL}/fashion/mensshoes/nike03.png`, category: 'Mens Shoes', stock:0, page:1, description:'good Quality', 
     thumbnails:
      [`${process.env.PUBLIC_URL}/fashion/mensshoes/nike03.png`,
       `${process.env.PUBLIC_URL}/fashion/mensshoes/nike03.png`,
      ],}, 

      ];