// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container {
    text-align: center;
    margin-top: 25px;
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}
.image-container{
   width: 250px;
    height:250px;
    margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ErrorBoundary.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;KACf,aAAa;IACd,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;AAE3B;AACA;GACG,YAAY;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".error-container {\n    text-align: center;\n    margin-top: 25px;\n     display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n   \n}\n.image-container{\n   width: 250px;\n    height:250px;\n    margin-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
